<template>
  <div class="google-map-container" :class="{ sparkasse }">
    <div id="google-map" ref="googleMapDiv" class="google-map ml-n3 mr-n3" :style="{ height: height }" />
    <MapZoomSlider v-model="zoom" @zoom-to-user="zoomToUser" />
  </div>
</template>

<script lang="ts">
import { Loader } from '@googlemaps/js-api-loader'
import MarkerClusterer from '@googlemaps/markerclustererplus'
import debounce from 'lodash/debounce'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'

import MapZoomSlider from '@/components/explore/MapZoomSlider.vue'
import { ILocation } from '@/types/projects'

@Component({
  name: 'google-map-loader',
  components: {
    MapZoomSlider,
  },
})
export default class GoogleMapLoader extends Vue {
  @Prop({ default: '' }) apiKey!: string
  @Prop({ default: () => [] }) locations!: ILocation[]
  @Prop() minMaxBounds!: google.maps.LatLngBoundsLiteral | undefined
  @Prop({ default: '450px' }) height!: string
  @Prop({ default: '' }) defaultMarkerIcon: string
  @Prop({ default: false }) sparkasse: boolean
  @Prop({ default: false }) isOrganizationExplore: boolean

  @Ref() readonly googleMapDiv!: HTMLDivElement

  googleMap: google.maps.Map = null
  mapMarkers: google.maps.Marker[] = []
  mapClusterer: MarkerClusterer = null
  zoom = 4

  @Watch('locations')
  onLocationsChange(): void {
    this.setMarkers()
  }

  @Watch('minMaxBounds')
  onMinMaxBoundsChange(): void {
    this.googleMap.fitBounds(this.minMaxBounds)
  }

  @Watch('zoom')
  onZoomChange(): void {
    this.googleMap.setZoom(this.zoom)
  }

  zoomToUser(): void {
    if ('geolocation' in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude
          const lng = position.coords.longitude

          this.zoom = 12
          this.googleMap.panTo(new google.maps.LatLng(lat, lng))
        }
      )
    }
  }

  initialiseMap(): void {
    const googleMapApi = new Loader({
      apiKey: this.apiKey,
      version: 'weekly',
    })

    googleMapApi.load().then(() => {
      this.googleMap = new google.maps.Map(document.querySelector('#google-map'), {
        center: {
          lat: 0,
          lng: 0,
        },
        zoom: this.zoom,
        disableDefaultUI: true,
        minZoom: 1,
        maxZoom: 16,
      })

      this.googleMap.addListener(
        'idle',
        debounce(() => {
          this.$emit('bounds-changed', this.googleMap.getBounds())
        }, 500)
      )

      this.googleMap.addListener('zoom_changed', () => {
        this.zoom = this.googleMap.getZoom()

        debounce(() => {
          this.$emit('zoom-changed', this.zoom)
        }, 500)
      })

      this.setMarkers()
      this.googleMap.fitBounds(this.minMaxBounds)
    })
  }

  setMarkers(): void {
    for (const mapMarker of this.mapMarkers) {
      mapMarker.setMap(null)
    }

    this.mapMarkers = this.locations.map((location) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(location.latitude, location.longitude),
        icon: location.marker_icon || this.defaultMarkerIcon,
      })

      const infowindow = new google.maps.InfoWindow()

      google.maps.event.addListener(marker, 'mouseover', () => {
        infowindow.setContent(`<h4>${location.project_title}</h4>`)
        infowindow.open(this.googleMap, marker)
      })

      google.maps.event.addListener(marker, 'mouseout', () => {
        infowindow.close()
      })

      google.maps.event.addListener(marker, 'click', () => {
        if (this.isOrganizationExplore) {
          this.$router.push({ name: 'organization-detail', params: { orgSlug: location.project_slug } })
        } else {
          this.$router.push({ name: 'project-detail', params: { projectSlug: location.project_slug } })
        }
      })

      return marker
    })

    if (this.mapClusterer) {
      this.mapClusterer.clearMarkers()
    }

    this.mapClusterer = new MarkerClusterer(this.googleMap, this.mapMarkers)
  }

  async mounted(): Promise<void> {
    this.initialiseMap()
  }
}
</script>
