<template>
  <b-card body-class="px-4 py-2" class="shadow-sm mb-3 bg-white rounded" :title="title" :sub-title="subTitle">
    <b-card-body class="p-2">
      <div class="row bg-light mb-2 rounded-lg">
        <div class="col-12 d-flex my-3 p-0">
          <div class="col-4">
            <div class="d-flex justify-content-center circular-icon-div rounded-circle">
              <i class="fa fa-trophy icon-style-base" aria-hidden="true" />
            </div>
          </div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <div>
              <span class="info-title">
                <strong>
                  <translate>Rank</translate>
                </strong>
              </span>
            </div>
            <div>
              <span class="ranking-data">
                <p v-translate="{ count: currentRank, totalCount: totalRank }" class="p-0 m-0">
                  <strong class="base-ci-color">%{ count } </strong>of %{ totalCount }
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row bg-light rounded-lg">
        <div class="col-12 d-flex my-3 p-0">
          <div class="col-4">
            <div class="d-flex justify-content-center circular-icon-div rounded-circle">
              <i class="fa fa-thumbs-o-up icon-style-base" aria-hidden="true" />
            </div>
          </div>
          <div class="col-8 d-flex flex-column justify-content-center">
            <div>
              <span class="info-title">
                <strong>
                  <translate>Received votes</translate>
                </strong>
              </span>
            </div>
            <div>
              <span class="voting-data">
                <p
                  v-translate="{ count: receivedVotes }"
                  class="p-0 m-0"
                  :translate-n="receivedVotes"
                  translate-plural="<strong class='mr-1 base-ci-color' variant='base-ci-color'>%{ count }</strong> Votes received"
                >
                  <strong class="base-ci-color">%{ count } </strong>Vote received
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script lang="ts">
import { IProject } from '@/types/projects'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'voting-info',
})
export default class VotingInfo extends Vue {
  @Prop() currentRank: number
  @Prop() totalRank: number
  @Prop() receivedVotes: number
  @Prop() project: IProject

  get title () {
    let today = new Date()
    let end = new Date(this.project.voting?.voting_end)
    if (this.project.voting && end < today) {
      return this.$gettext('Reached Rank')
    } else {
      return this.$gettext('Current Rank')
    }
  }

  get subTitle () {
    if (!this.project.voting) {
      return ''
    }
    let start = this.project.voting.voting_start
    let end = this.project.voting.voting_end
    return this.$gettextInterpolate(this.$gettext('Voting phase: %{ start } to %{ end }'), { start: this.$moment(start).format('L'), end: this.$moment(end).format('L') })
  }
}
</script>
